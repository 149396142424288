<template>
    <v-row>
        <v-col cols="12" md="6">
            <h3 class="admin-form-title">Добавить категорию</h3>
            <v-card class="pa-4 pa-xl-8 admin-form-card">
                <v-card-text class="pa-0">
                    <v-form ref="createCategoryForm">
                        <v-text-field v-model="categoryAdd.name" :rules="rules.cantEmpty" :height="inputHeight"
                                      class="admin-form-card__input" label="Название" solo hide-details></v-text-field>
                    </v-form>
                    <v-btn class="button admin-form-card__btn mx-auto mt-xl-8 mt-lg-6 mt-4" :height="inputHeight"
                           color="#018ABE" @click="createCategory()">Добавить категорию
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" md="6">
            <h3 class="admin-form-title">Добавить товар</h3>
            <v-card class="pa-4 pa-xl-8 admin-form-card">
                <v-card-text class="pa-0">
                    <v-form ref="createProductForm">
                        <v-text-field v-model="productAdd.name" :rules="rules.cantEmpty"
                                      :height="inputHeight" class="admin-form-card__input" label="Название" solo
                                      hide-details></v-text-field>
                        <v-select v-model="productAdd.categoryId" :rules="rules.cantEmpty" :items="categories"
                                  item-text="name" item-value="id"
                                  :height="inputHeight" class="admin-form-card__input mt-5" label="Категория" solo
                                  hide-details></v-select>
                        <v-textarea v-model="productAdd.shortDesc" :rules="rules.cantEmpty" rows="3"
                                    class="admin-form-card__input mt-5" label="Краткое описание" solo
                                    hide-details></v-textarea>
                        <VueEditor class="admin-form-card__editor mt-5" v-model="productAdd.desc" placeholder="Описание" :editor-toolbar="customToolbar"></VueEditor>
                        <v-file-input v-model="uploadedPreviewImage"
                                      :height="inputHeight" class="admin-form-card__input mt-5" label="Превью" solo
                                      hide-details></v-file-input>
                        <v-file-input v-model="uploadedImages"
                                      :height="inputHeight" class="admin-form-card__input mt-5" label="Изображения"
                                      multiple solo hide-details></v-file-input>
                    </v-form>
                    <v-row class="mt-5 pb-8" v-if="productAdd.preview || productAdd.images.length">
                        <v-col cols="3" v-if="productAdd.preview">
                            <span>Превью</span>
                            <div class="d-flex h-100 mt-2">
                                <v-img :src="productAdd.preview.src" cover>
                  <span style="position: absolute; right: 0; top: 0; z-index: 2; background: rgba(0, 0, 0, 0.5)">
                    <v-icon size="36" @click="productAdd.preview = null; uploadedPreviewImage = null"
                            color="white">mdi-close</v-icon>
                  </span>
                                </v-img>
                            </div>
                        </v-col>
                        <v-col cols="3" v-for="(image, index) of productAdd.images" :key="'image-' + index">
                            <span>Изображение {{ index + 1 }}</span>
                            <div class="d-flex h-100 mt-2">
                                <v-img :src="image.src" cover>
                  <span style="position: absolute; right: 0; top: 0; z-index: 2; background: rgba(0, 0, 0, 0.5)">
                    <v-icon size="36" @click="productAdd.images.splice(index, 1); uploadedImages.splice(index, 1)"
                            color="white">mdi-close</v-icon>
                  </span>
                                </v-img>
                            </div>
                        </v-col>
                    </v-row>
                    <v-btn class="button admin-form-card__btn mx-auto mt-xl-8 mt-lg-6 mt-4" :height="inputHeight"
                           color="#018ABE" @click="createProduct()">Добавить товар
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="12">
            <h3 class="admin-subtitle">Редактирование</h3>
        </v-col>
        <v-col cols="12" lg="8">
            <v-tabs v-model="activeTab" grow show-arrows>
                <v-tab v-for="category of categories" :key="'category-' + category.id">{{ category.name }}</v-tab>
            </v-tabs>
        </v-col>
        <v-col cols="12" lg="4">
            <div class="d-flex justify-sm-end justify-center">
                <v-btn class="button admin-button px-xl-8" @click="openUpdateCategoryDialog(categories[activeTab].id)">
                    Редактировать
                    <v-icon right>mdi-square-edit-outline</v-icon>
                </v-btn>
                <v-btn class="button ml-6 admin-button__fill px-xl-8"
                       @click="openConfirmDeleteCategoryDialog(categories[activeTab].id)">Удалить
                    <v-icon right>mdi-delete-forever</v-icon>
                </v-btn>
            </div>
        </v-col>
        <v-col cols="12">
            <v-tabs-items v-model="activeTab" style="overflow: visible">
                <v-tab-item v-for="category of categories" :key="'category-item-' + category.id">
                    <v-row>
                        <v-col cols="6" sm="4" md="3" xl="2" v-for="product of category.products"
                               :key="'product-' + product.id" class="pa-md-3 pa-1">
                            <CustomCard @edit-product="openUpdateProductDialog"
                                        @delete-product="openConfirmDeleteProduct" :product="product"
                                        :category="{id: category.id, name: category.name}"></CustomCard>
                        </v-col>
                    </v-row>
                </v-tab-item>
            </v-tabs-items>
        </v-col>


        <v-dialog max-width="600" v-model="updateCategoryDialogData.show" @click:outside="closeUpdateCategoryDialog()"
                  @keydown.esc="closeUpdateCategoryDialog()">
            <v-card class="pa-4 pa-xl-8 admin-form-card">
                <v-card-title class="justify-center">Редактирование категории</v-card-title>
                <v-card-text class="pa-0">
                    <v-form ref="updateCategoryForm">
                        <v-text-field v-model="updateCategoryDialogData.name" :rules="rules.cantEmpty"
                                      :height="inputHeight" class="admin-form-card__input" label="Название" solo
                                      hide-details></v-text-field>
                    </v-form>
                    <v-btn class="button admin-form-card__btn mx-auto mt-xl-8" width="60%" :height="inputHeight"
                           color="#018ABE" @click="updateCategory()">Редактировать категорию
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog max-width="600" v-model="confirmDeleteCategory.show" @click:outside="closeConfirmDeleteCategory()"
                  @keydown.esc="closeConfirmDeleteCategory()">
            <v-card class="admin-card pa-4 pa-xl-8" color="rgba(255, 255, 255, 1)" light>
                <v-card-text class="d-flex flex-column pa-0">
                    <v-checkbox :label="'Я хочу удалить категорию `' + confirmDeleteCategory.name + '`'"
                                v-model="confirmDeleteCategory.check"></v-checkbox>
                    <span>Так же с категорией удаляться все товары в ней, их не вернуть!</span>
                    <v-btn width="60%" class="mt-4 align-self-center" :disabled="!confirmDeleteCategory.check"
                           @click="deleteCategory()">Удалить
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog max-width="900" v-model="updateProductDialogData.show" @click:outside="closeUpdateProductDialog()"
                  @keydown.esc="closeUpdateProductDialog()">
            <v-card class="admin-card pa-4 pa-xl-8" color="rgba(255, 255, 255, 1)" light>
                <v-card-title class="justify-center">Редактирование товара</v-card-title>
                <v-card-text class="pa-0">
                    <v-form ref="updateProductForm">
                        <v-text-field v-model="updateProductDialogData.name" :rules="rules.cantEmpty"
                                      :height="inputHeight" class="admin-form-card__input" label="Название" solo
                                      hide-details></v-text-field>
                        <v-select v-model="updateProductDialogData.categoryIdNew" :rules="rules.cantEmpty"
                                  :items="categories" item-text="name" item-value="id"
                                  :height="inputHeight" class="admin-form-card__input mt-5" label="Категория" solo
                                  hide-details></v-select>
                        <v-textarea v-model="updateProductDialogData.shortDesc" :rules="rules.cantEmpty" rows="3"
                                    class="admin-form-card__input mt-5" label="Краткое описание" solo
                                    hide-details></v-textarea>
                        <VueEditor class="admin-form-card__editor mt-5" v-model="updateProductDialogData.desc" placeholder="Описание" :editor-toolbar="customToolbar"></VueEditor>
                        <v-file-input v-model="newUploadedPreviewImage"
                                      :height="inputHeight" class="admin-form-card__input mt-5" label="Новое превью"
                                      solo hide-details></v-file-input>
                        <v-file-input v-model="newUploadedImages"
                                      :height="inputHeight" class="admin-form-card__input mt-5"
                                      label="Новые изображения" multiple solo hide-details></v-file-input>
                    </v-form>
                    <v-row class="mt-5 pb-8"
                           v-if="updateProductDialogData.preview || updateProductDialogData.newPreview
                 || (updateProductDialogData.images && updateProductDialogData.images.length)
                 || (updateProductDialogData.newImages && updateProductDialogData.newImages.length)">
                        <v-col cols="3" v-if="updateProductDialogData.preview">
                            <span>Превью</span>
                            <div class="d-flex h-100 mt-2">
                                <v-img :src="backendUrl + '/api/image/getProductImage/' + updateProductDialogData.preview"
                                       cover>
                  <span style="position: absolute; right: 0; top: 0; z-index: 2; background: rgba(0, 0, 0, 0.5)">
                    <v-icon size="36" @click="updateProductDialogData.preview = null; newUploadedPreviewImage = null"
                            color="white">mdi-close</v-icon>
                  </span>
                                </v-img>
                            </div>
                        </v-col>
                        <v-col cols="3" v-if="updateProductDialogData.newPreview">
                            <span>Новое превью</span>
                            <div class="d-flex h-100 mt-2">
                                <v-img :src="updateProductDialogData.newPreview.src" cover>
                  <span style="position: absolute; right: 0; top: 0; z-index: 2; background: rgba(0, 0, 0, 0.5)">
                    <v-icon size="36" @click="updateProductDialogData.newPreview = null; newUploadedPreviewImage = null"
                            color="white">mdi-close</v-icon>
                  </span>
                                </v-img>
                            </div>
                        </v-col>
                        <v-col cols="3" v-for="(image, index) of updateProductDialogData.newImages"
                               :key="'image-' + index">
                            <span>Изображение {{ index + 1 }}</span>
                            <div class="d-flex h-100 mt-2">
                                <v-img :src="image.src" cover>
                  <span style="position: absolute; right: 0; top: 0; z-index: 2; background: rgba(0, 0, 0, 0.5)">
                    <v-icon size="36"
                            @click="updateProductDialogData.newImages.splice(index, 1); newUploadedImages.splice(index, 1)"
                            color="white">mdi-close</v-icon>
                  </span>
                                </v-img>
                            </div>
                        </v-col>
                        <v-col cols="3" v-for="(image, index) of updateProductDialogData.images"
                               :key="'newImage-' + index">
                            <span>Новое изображение {{ index + 1 }}</span>
                            <div class="d-flex h-100 mt-2">
                                <v-img :src="backendUrl + '/api/image/getProductImage/' + image.filename" cover>
                  <span style="position: absolute; right: 0; top: 0; z-index: 2; background: rgba(0, 0, 0, 0.5)">
                    <v-icon size="36"
                            @click="updateProductDialogData.images.splice(index, 1); updateProductDialogData.deletedImages.push(image.id)"
                            color="white">mdi-close</v-icon>
                  </span>
                                </v-img>
                            </div>
                        </v-col>
                    </v-row>
                    <v-btn class="button admin-form-card__btn mx-auto mt-xl-8" width="60%" :height="inputHeight"
                           color="#018ABE" @click="updateProduct()">Редактировать товар
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog max-width="600" v-model="confirmDeleteProduct.show" @click:outside="closeConfirmDeleteProduct()"
                  @keydown.esc="closeConfirmDeleteProduct()">
            <v-card class="admin-card pa-4 pa-xl-8" color="rgba(255, 255, 255, 1)" light>
                <v-card-text class="d-flex flex-column pa-0">
                    <v-checkbox :label="'Я хочу удалить товар - ' + confirmDeleteProduct.name"
                                v-model="confirmDeleteProduct.check"></v-checkbox>
                    <v-btn width="60%" class="mt-4 align-self-center" :disabled="!confirmDeleteProduct.check"
                           @click="deleteProduct()">Удалить
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import {VueEditor} from "vue2-editor"

import CategoryService from "@/services/category.service";
import ProductService from "@/services/product.service";
import CustomCard from "@/components/oftenUsed/CustomCard";

export default {
    name: "AdminCategories",
    components: {CustomCard, VueEditor},
    mounted() {
        CategoryService.getCategories().then(response => {
            this.categories = response.data
        })
    },
    data() {
        return {
            activeTab: 0,

            categories: [],
            categoryAdd: {
                name: ''
            },
            productAdd: {
                name: '',
                categoryId: '',
                shortDesc: '',
                desc: '',
                preview: null,
                images: []
            },

            uploadedPreviewImage: null,
            uploadedImages: null,


            updateCategoryDialogData: {
                show: false,
                id: '',
                index: '',
                name: ''
            },
            confirmDeleteCategory: {
                show: false,
                id: '',
                index: '',
                name: '',
                check: false
            },
            updateProductDialogData: {
                show: false,
                id: '',
                categoryId: '',
                categoryIdNew: '',
                index: '',
                categoryIndex: '',
                name: '',
                shortDesc: '',
                desc: '',
                preview: null,
                newPreview: null,
                images: [],
                newImages: [],
                deletedImages: []
            },

            newUploadedPreviewImage: null,
            newUploadedImages: null,

            confirmDeleteProduct: {
                show: false,
                id: '',
                index: '',
                categoryIndex: '',
                name: '',
                check: false
            },

            rules: {
                cantEmpty: [
                    v => !!v || 'Не может быть пустым'
                ]
            },

            customToolbar: [
                ["bold", "italic", "underline"],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }]
            ]
        }
    },
    computed: {
        inputHeight() {
            return this.windowWidth >= 1920 ? '64px' : '56px'
        },
        backendUrl() {
            return process.env.VUE_APP_API_URL
        }
    },
    methods: {
        createCategory() {
            let valid = this.$refs.createCategoryForm.validate()

            if (valid) {
                CategoryService.createCategory(this.categoryAdd).then(response => {
                    this.categories.push(response.data.category)
                    this.$refs.createCategoryForm.reset()
                })
            }
        },
        updateCategory() {
            let valid = this.$refs.updateCategoryForm.validate()

            if (valid) {
                CategoryService.updateCategory(this.updateCategoryDialogData).then(response => {
                    this.categories[this.updateCategoryDialogData.index] = response.data.category
                    this.closeUpdateCategoryDialog()
                })
            }
        },
        deleteCategory() {
            if (this.confirmDeleteCategory.check) {
                CategoryService.deleteCategory(this.confirmDeleteCategory.id).then(() => {
                    this.categories.splice(this.confirmDeleteCategory.index, 1)
                    this.closeConfirmDeleteCategory()
                })
            }
        },

        createProduct() {
            let valid = this.$refs.createProductForm.validate()

            if (valid) {
                const formData = new FormData()
                formData.append('name', this.productAdd.name)
                formData.append('categoryId', this.productAdd.categoryId)
                formData.append('shortDesc', this.productAdd.shortDesc)
                formData.append('desc', this.productAdd.desc)

                formData.append('preview', this.productAdd.preview.data)

                if (this.productAdd.images && this.productAdd.images.length) {
                    for (let i = 0; i < this.productAdd.images.length; i++) {
                        formData.append('images', this.productAdd.images[i].data);
                    }
                }

                ProductService.createProduct(formData).then(response => {
                    let index = this.categories.findIndex(el => el.id === this.productAdd.categoryId)
                    this.categories[index].products.push(response.data.product)
                    this.$refs.createProductForm.reset()
                })
            }
        },
        updateProduct() {
            let valid = this.$refs.updateProductForm.validate()

            if (valid) {
                const formData = new FormData()

                formData.append('id', this.updateProductDialogData.id)
                formData.append('categoryId', this.updateProductDialogData.categoryId)
                formData.append('categoryIdNew', this.updateProductDialogData.categoryIdNew)
                formData.append('name', this.updateProductDialogData.name)
                formData.append('shortDesc', this.updateProductDialogData.shortDesc)
                formData.append('desc', this.updateProductDialogData.desc)

                if (this.updateProductDialogData.newPreview) {
                    formData.append('newPreview', this.updateProductDialogData.newPreview.data)
                }

                if (this.updateProductDialogData.newImages && this.updateProductDialogData.newImages.length) {
                    for (let i = 0; i < this.updateProductDialogData.newImages.length; i++) {
                        formData.append('newImages', this.updateProductDialogData.newImages[i].data)
                    }
                }

                if (this.updateProductDialogData.deletedImages && this.updateProductDialogData.deletedImages.length) {
                    formData.append('deletedImages', JSON.stringify(this.updateProductDialogData.deletedImages))
                }

                ProductService.updateProduct(formData).then(response => {
                    if (!response.data.error) {
                        if (this.updateProductDialogData.categoryId !== this.updateProductDialogData.categoryIdNew) {
                            this.categories[this.updateProductDialogData.categoryIndex].products.splice(this.updateProductDialogData.index, 1)
                            this.categories.find(el => el.id === this.updateProductDialogData.categoryIdNew).products.push(response.data.product)
                        } else {
                            this.categories[this.updateProductDialogData.categoryIndex].products[this.updateProductDialogData.index] = response.data.product
                        }

                        this.closeUpdateProductDialog()

                        this.$store.dispatch('notify/newNotify', {
                            type: 'success',
                            text: 'Сохранено'
                        })
                    } else {
                        this.$store.dispatch('notify/newNotify', {
                            type: 'error',
                            text: 'Ошибка'
                        })
                    }
                })
            }
        },
        deleteProduct() {
            if (this.confirmDeleteProduct.check) {
                ProductService.deleteProduct(this.confirmDeleteProduct.id).then(() => {
                    this.categories[this.confirmDeleteProduct.categoryIndex].products.splice(this.confirmDeleteProduct.index, 1)
                    this.closeConfirmDeleteProduct()
                })
            }
        },

        convertPreviewImage(image) {
            this.productAdd.preview = {
                src: URL.createObjectURL(image),
                data: image
            }
        },
        convertImages(images) {
            images.forEach(image => {
                const exists = this.productAdd.images.find(el => el.data.lastModified === image.lastModified)
                if (!exists) {
                    if (this.productAdd.images.length >= 3) {
                        return this.$store.dispatch('notify/newNotify', {
                            type: 'error',
                            text: 'Не более 3 изображений на товар'
                        })
                    } else {
                        this.productAdd.images.push({
                            src: URL.createObjectURL(image),
                            data: image
                        })
                    }
                }
            })
        },

        convertNewPreviewImage(image) {
            this.updateProductDialogData.newPreview = {
                src: URL.createObjectURL(image),
                data: image
            }
        },
        convertNewImages(images) {
            images.forEach(image => {
                const exists = this.updateProductDialogData.newImages.find(el => el.data.lastModified === image.lastModified)
                if (!exists) {
                    if (this.updateProductDialogData.newImages.length + this.updateProductDialogData.images.length >= 3) {
                        return this.$store.dispatch('notify/newNotify', {
                            type: 'error',
                            text: 'Не более 3 изображений на товар'
                        })
                    } else {
                        this.updateProductDialogData.newImages.push({
                            src: URL.createObjectURL(image),
                            data: image
                        })
                    }
                }
            })
        },


        openUpdateCategoryDialog(id) {
            let index = this.categories.findIndex(el => el.id === id)
            this.updateCategoryDialogData.id = this.categories[index].id
            this.updateCategoryDialogData.index = index
            this.updateCategoryDialogData.name = this.categories[index].name
            this.updateCategoryDialogData.show = true
        },
        closeUpdateCategoryDialog() {
            this.updateCategoryDialogData.show = false
            this.updateCategoryDialogData.id = ''
            this.updateCategoryDialogData.index = ''
            this.updateCategoryDialogData.name = ''
        },
        openConfirmDeleteCategoryDialog(id) {
            let index = this.categories.findIndex(el => el.id === id)
            this.confirmDeleteCategory.id = id
            this.confirmDeleteCategory.index = index
            this.confirmDeleteCategory.name = this.categories[index].name
            this.confirmDeleteCategory.show = true
        },
        closeConfirmDeleteCategory() {
            this.confirmDeleteCategory.show = false
            this.confirmDeleteCategory.id = ''
            this.confirmDeleteCategory.index = ''
            this.confirmDeleteCategory.name = ''
        },

        openUpdateProductDialog(productId, categoryId) {
            let categoryIndex = this.categories.findIndex(el => el.id === categoryId)
            let productIndex = this.categories[categoryIndex].products.findIndex(el => el.id == productId)
            this.updateProductDialogData.id = this.categories[categoryIndex].products[productIndex].id
            this.updateProductDialogData.categoryId = this.categories[categoryIndex].id
            this.updateProductDialogData.categoryIdNew = this.categories[categoryIndex].id
            this.updateProductDialogData.index = productIndex
            this.updateProductDialogData.categoryIndex = categoryIndex
            this.updateProductDialogData.name = this.categories[categoryIndex].products[productIndex].name
            this.updateProductDialogData.shortDesc = this.categories[categoryIndex].products[productIndex].shortDesc
            this.updateProductDialogData.desc = this.categories[categoryIndex].products[productIndex].desc
            this.updateProductDialogData.preview = this.categories[categoryIndex].products[productIndex].preview
            this.updateProductDialogData.images = this.categories[categoryIndex].products[productIndex].productImages
            this.updateProductDialogData.show = true
        },
        closeUpdateProductDialog() {
            this.updateProductDialogData.show = false
            this.updateProductDialogData.id = ''
            this.updateProductDialogData.categoryId = ''
            this.updateProductDialogData.categoryIdNew = ''
            this.updateProductDialogData.index = ''
            this.updateProductDialogData.categoryIndex = ''
            this.updateProductDialogData.name = ''
            this.updateProductDialogData.shortDesc = ''
            this.updateProductDialogData.desc = ''
            this.updateProductDialogData.preview = null
            this.updateProductDialogData.newPreview = null
            this.updateProductDialogData.images = []
            this.updateProductDialogData.newImages = []
            this.updateProductDialogData.deletedImages = []
        },
        openConfirmDeleteProduct(productId, categoryId) {
            let categoryIndex = this.categories.findIndex(el => el.id === categoryId)
            let productIndex = this.categories[categoryIndex].products.findIndex(el => el.id === productId)
            this.confirmDeleteProduct.id = productId
            this.confirmDeleteProduct.index = productIndex
            this.confirmDeleteProduct.categoryIndex = categoryIndex
            this.confirmDeleteProduct.name = this.categories[categoryIndex].products[productIndex].name
            this.confirmDeleteProduct.show = true
        },
        closeConfirmDeleteProduct() {
            this.confirmDeleteProduct.show = false
            this.confirmDeleteProduct.id = ''
            this.confirmDeleteProduct.index = ''
            this.confirmDeleteProduct.categoryIndex = ''
            this.confirmDeleteProduct.name = ''
        },
    },
    watch: {
        uploadedPreviewImage(newValue) {
            if (newValue === null) {
                this.productAdd.preview = null
            } else {
                this.convertPreviewImage(newValue)
            }
        },
        uploadedImages(newValue) {
            if (!newValue.length) {
                this.productAdd.images = []
            } else {
                this.convertImages(newValue)
            }
        },
        newUploadedPreviewImage(newValue) {
            if (newValue === null) {
                this.updateProductDialogData.newPreview = null
            } else if (this.updateProductDialogData.preview) {
                return this.$store.dispatch('notify/newNotify', {
                    type: 'error',
                    text: 'Сначала удалите предыдущее превью'
                })
            } else {
                this.convertNewPreviewImage(newValue)
            }
        },
        newUploadedImages(newValue) {
            if (!newValue.length) {
                this.updateProductDialogData.newImages = []
            } else if (this.updateProductDialogData.images >= 3) {
                return this.$store.dispatch('notify/newNotify', {
                    type: 'error',
                    text: 'Не более 3 изображений на товар'
                })
            } else {
                this.convertNewImages(newValue)
            }
        }
    }
}
</script>

<style scoped lang="sass">
.admin
  &-form
    &-title
      font-family: "Roboto Regular"
      font-size: 28px
      margin-top: 16px
      margin-bottom: 20px

    &-card
      background: #F8F8F8
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15)
      border-radius: 12px

      &__input
        border-radius: 10px
        font-size: 18px

        &.error--text
          outline: 2px solid #ff5252

      &__editor
        background: #ffffff
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15)
        border-radius: 12px

      &__btn
        width: 60%
        font-size: 18px
        color: #ffffff

  &-subtitle
    text-align: center
    font-family: "Roboto Regular"
    font-size: 28px
    margin-top: 16px
    margin-bottom: 20px

  &-button
    color: #018ABE
    background-color: rgba(0, 0, 0, 0)
    border: 1px solid #018ABE

    &__fill
      color: #ffffff
      background-color: #018ABE !important

@media (max-width: 599.98px)
  .admin
    &-form
      &-title
        font-size: 18px

      &-card
        &__btn
          width: 100%
          font-size: 18px
          color: #ffffff

    &-button, &-button__fill
      font-size: 12px
</style>
